import useWebSocket from "react-use-websocket";
import { useDispatch } from "react-redux";
import {
  ADD_TASK,
  DELETE_REPEAT_TASK,
  DELETE_TASK,
  UPDATE_TASK,
} from "../actions/types";
import { createContext, useContext, useEffect } from "react";
import { ip, ws } from "./ipconfig";
const WebSocketContext = createContext(null);
export const WebSocketProvider = ({ children }) => {
  const dispatch = useDispatch();

  // Initialize the WebSocket connection
  const { sendMessage, lastMessage } = useWebSocket(ws, {
    shouldReconnect: () => true,
    onOpen: () => console.log("WebSocket connection established."),

    onError: (event) => {
      console.log("WebSocket error:", event);
    },

    onMessage: (event) => {
      // Parse the incoming JSON message
      const message = JSON.parse(event.data);
      console.log("WebSocket message received:", message);
      // Dispatch Redux actions based on the message type
      switch (message.type) {
        case "TASK_CREATED":
          dispatch({ type: ADD_TASK, payload: message.task });
          break;
        case "TASK_UPDATED":
        case "TASK_APPROVED":
          dispatch({ type: UPDATE_TASK, payload: message.task });
          break;
        case "TASK_STATUS_UPDATED":
          dispatch({ type: UPDATE_TASK, payload: message.task });
          break;
        case "TASK_DELETED":
          dispatch({ type: DELETE_TASK, payload: message.taskId });
          break;
        case "TASK_GROUPID_DELETED":
          dispatch({ type: DELETE_REPEAT_TASK, payload: message.groupId });
          break;
        default:
          console.warn("Unknown message type:", message.type);
      }
    },

    onClose: () => console.log("WebSocket connection closed."),
  });

  return (
    <WebSocketContext.Provider value={{ sendMessage, lastMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};
export const useWebSocketService = () => useContext(WebSocketContext);
