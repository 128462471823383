import axios from "axios";
import { ip } from "../utils/ipconfig";
import toast from "react-hot-toast";
export const getAllUsersPerformance = async () => {
  try {
    const response = await axios.get(`${ip}/api/dashboard/admin`);
    return response.data;

  } catch (error) {
   console.log(error);
    toast.error(error.message)
  }
};

export const getSingleUserPerformance = async (emailID) => {
  try {
    const response = await axios.get(`${ip}/api/dashboard/user/${emailID}`);

    return response.data;
  } catch (error) {
    toast.error(error.message)
    
  }
};

export const getAllCategoryPerformance = async () => {
  try {
    const response = await axios.get(
      `${ip}/api/dashboard/allcategoryperformance`
    );
    return response;
  } catch (error) {
    toast.error(error.message)
  }
};

export const getMyReport = async () => {
  try {
    const response = await axios.get(`${ip}/api/dashboard/my-report`);

    return response;
  } catch (error) {
    toast.error(error.message)
  }
};
export const getDelegate = async () => {
  try {
    const response = await axios.get(
      `${ip}/api/dashboard/delegated-tasks-performance`
    );

    return response;
  } catch (error) {
    toast.error(error.message)
  }
};

export const getUserStats = async () => {
  try {
    const response = await axios.get(`${ip}/api/dashboard/user`);
    console.log(response);

    return response;
  } catch (error) {
    toast.error(error.message)
  }
};
