import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Chip,
  CircularProgress,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardCard from "../../../components/shared/DashboardCard";
import moment from "moment";

import { useLocation, useParams } from "react-router-dom";

// Custom styles for the dashboard
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
    "&.active": {
      backgroundColor: "#5D87FF", // Active card color (green)
      color: "white",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
  },
  tableHeader: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9f9f9",
    },
    "&:hover": {
      backgroundColor: "#e0f7fa",
    },
  },
  cardTitle: {
    fontSize: "1.1rem",
    fontWeight: "500",
    paddingBottom: "10px",
  },
  cardValue: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  container: {
    padding: "30px",
  },
}));

const TaskOverview = ({ taskData }) => {
  console.log("Task Data", taskData);

  const classes = useStyles();
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [activeCard, setActiveCard] = useState("all");

  // Initialize filtered tasks based on the "all" category
  useEffect(() => {
    const allTasks = [
      ...(taskData.openTasks || []),
      ...(taskData.inProgressTasks || []),
      ...(taskData.completedTasks || []),
      ...(taskData.overdueTasks || []),
      ...(taskData.delayedTasks || []),
    ];
    setFilteredTasks(allTasks);
  }, [taskData]);

  // Handle card click to filter tasks
  const handleCardClick = (category) => {
    setActiveCard(category);
    switch (category) {
      case "completed":
        setFilteredTasks(taskData.completedTasks || []);
        break;
      case "open":
        setFilteredTasks(taskData.openTasks || []);
        break;
      case "inProgress":
        setFilteredTasks(taskData.inProgressTasks || []);
        break;
      case "overdue":
        setFilteredTasks(taskData.overdueTasks || []);
        break;
      case "onTime":
        setFilteredTasks(taskData.onTimeTasks || []);
        break;
      case "delayed":
        setFilteredTasks(taskData.delayedTasks || []);
        break;
      default:
        setFilteredTasks([
          ...(taskData.openTasks || []),
          ...(taskData.inProgressTasks || []),
          ...(taskData.completedTasks || []),
          ...(taskData.overdueTasks || []),
          ...(taskData.delayedTasks || []),
        ]);
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "primary.main";
      case "Medium":
        return "secondary.main";
      case "Low":
        return "error.main";
      case "Critical":
        return "success.main";
      default:
        return "default";
    }
  };

  return (
    <div className={classes.container}>
      <DashboardCard title={`Task overview of (${taskData.category}) category`}>
        {/* Statistics Cards */}
        <Grid container spacing={3}>
          {[
            { label: "Total Tasks", value: taskData.totalTasks, key: "all" },
            {
              label: "Completed Tasks",
              value: taskData.completedTasks.length || 0,
              key: "completed",
            },
            {
              label: "Open Tasks",
              value: taskData.openTasks.length || 0,
              key: "open",
            },
            {
              label: "In Progress",
              value: taskData.inProgressTasks.length || 0,
              key: "inProgress",
            },
            {
              label: "Overdue Tasks",
              value: taskData.overdueTasks.length || 0,
              key: "overdue",
            },
            {
              label: "On-Time Tasks",
              value: taskData.onTimeTasks.length || 0,
              key: "onTime",
            },
            {
              label: "Delayed Tasks",
              value: taskData.delayedTasks.length || 0,
              key: "delayed",
            },
            {
              label: "Completion Rate",
              value: `${taskData.completionRate}%`,
              key: "completionRate",
            },
          ].map(({ label, value, key }) => (
            <Grid item xs={12} sm={6} md={3} key={key}>
              <Card
                className={`${classes.card} ${
                  activeCard === key ? "active" : ""
                }`}
                onClick={() => handleCardClick(key)}
              >
                <CardContent>
                  <Typography className={classes.cardTitle}>{label}</Typography>
                  <Typography className={classes.cardValue}>{value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Divider style={{ marginTop: "20px" }} />

        {/* Task Table */}
        <Box sx={{ width: "100%" }}>
          <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Id
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Category
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Assign To
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Priority
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Due Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTasks.length > 0 ? (
                filteredTasks.map((task, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight={600}>
                        {task.title}
                      </Typography>
                    </TableCell>
                    <TableCell>{task.category}</TableCell>
                    <TableCell>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                        fontWeight={400}
                      >
                        {task.assignTo.join(", ")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        sx={{
                          px: "4px",
                          backgroundColor: getPriorityColor(task.priority),
                          color: "#fff",
                          height: 25,
                          width: 100,
                        }}
                        size="small"
                        label={task.priority}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        style={{ color: "red" }}
                      >
                        {moment(task.dueDate).format("DD-MMMM-YYYY hh:mm A")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      No tasks available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
    </div>
  );
};


const SpecificCategoryStatsReport = () => {
  const { state: taskData } = useLocation(); // Destructure taskData from location.state




  
  if (!taskData || Object.keys(taskData).length === 0) {
    return (
      <Typography variant="body1" align="center">
        No data available for the selected category.
      </Typography>
    );
  }

  return (
    <>
      {taskData ? (
        <TaskOverview taskData={taskData} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default SpecificCategoryStatsReport;
