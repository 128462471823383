import { CssBaseline, Fab, ThemeProvider, Tooltip } from "@mui/material";
import { useNavigate, useRoutes } from "react-router-dom";
import Router from "./routes/Router";
import { PersistGate } from "redux-persist/integration/react";
import { baselightTheme } from "./theme/DefaultColors";
import setAuthToken from "./utils/setAuthToken";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import AddIcon from "@mui/icons-material/Add";
import { Toaster } from "react-hot-toast";
import "../src/index.css";
import { WebSocketProvider } from "./utils/webSocket";
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  const navigate = useNavigate();
  const routing = useRoutes(Router);
  const theme = baselightTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <WebSocketProvider> {routing}</WebSocketProvider>
          <Toaster
            toastOptions={{
              className: "",
              duration: 5000,
              style: {
                border: "1px solid #d3e0ff",
                color: "#6D9BFF",
                fontWeight: "bolder",
              },
            }}
          />
          <Tooltip
            title="Add Task"
            aria-label="add task"
            placement="right-start"
          >
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => navigate("/add-task")}
              style={{
                position: "fixed",
                bottom: "16px",
                right: "16px",
                zIndex: 1000,
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
