//aws live ip's
export const ip = "https://tms-api.in";
export const ws = "wss://tms-api.in/";

// export const ip = "http://13.203.53.210";
// export const ws = "ws://13.203.53.210/";


// export const ip = "http://192.168.1.120:5001";
// export const ws = "ws://192.168.1.120:5001/";
