import React, { useEffect, useState } from "react";
import DashboardCard from "../../../components/shared/DashboardCard";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRepeatTask,
  deleteTask,
  getDelayedTasks,
  getRecurringTasks,
} from "../../../actions/taskActions";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { useWebSocketService } from "../../../utils/webSocket";

const RecurringTasks = () => {
  const { sendMessage } = useWebSocketService();
  const { tasks, loading: tasksLoading } = useSelector((state) => state.tasks);
  const { user } = useSelector((state) => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRecurringTasks());
  }, [dispatch]);

  const handleDeleteClick = (task) => {
    setSelectedTask(task);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTask(null);
  };

  const handleDeleteThisOnly = () => {
    if (selectedTask) {
      dispatch(deleteTask(selectedTask._id, sendMessage));
    }
    handleCloseModal();
  };

  const handleDeleteThisAndFollowing = () => {

    if (selectedTask) {
      const groupId = selectedTask.groupId;
      const taskId = selectedTask._id;
      

      dispatch(deleteRepeatTask(groupId, taskId, sendMessage));
    }
    handleCloseModal();
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "primary.main";
      case "Medium":
        return "secondary.main";
      case "Low":
        return "error.main";
      case "Critical":
        return "success.main";
      default:
        return "default";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Open":
        return "success";
      case "In Progress":
        return "warning";
      case "Completed":
        return "error";
      default:
        return "default";
    }
  };

  // const handleDeleteClick = (groupId, taskId) => {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to delete this & following tasks?"
  //   );
  //   if (confirmed) {
  //     dispatch(deleteRepeatTask(groupId, taskId,sendMessage));
  //   }
  // };

  if (tasksLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div style={{ width: "fit-content" }}>
      <DashboardCard title="Recurring Tasks">
        <Box sx={{ width: "100%" }}>
          <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Id
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Task Title
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Assigned To
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Priority
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Complete On
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Delayed
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((taskDetail, index) => (
                <TableRow key={taskDetail.id}>
                  <TableCell>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle2" fontWeight={600}>
                          {taskDetail.title}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      fontWeight={400}
                    >
                      {taskDetail.assignTo[0].userName}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Chip
                      sx={{
                        px: "4px",
                        backgroundColor: getPriorityColor(taskDetail.priority),
                        color: "#fff",
                        height: 25,
                        width: 100,
                      }}
                      style={{ fontWeight: "900" }}
                      size="small"
                      label={taskDetail.priority}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={taskDetail.status}
                      color={getStatusColor(taskDetail.status)}
                      size="small"
                    />
                  </TableCell>

                  <TableCell>
                    <Typography variant="subtitle2" style={{ color: "red" }}>
                      {moment(taskDetail.dueDate).format(
                        "DD-MMMM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#3f51b5" }}
                    >
                      {moment(taskDetail.dueDate).fromNow()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {user.role !== "User" &&
                    user.emailID === taskDetail.createdBy.emailID ? (
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleDeleteClick(taskDetail)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : (
                      <IconButton aria-label="delete" color="error" disabled>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          Delete Task
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to delete <strong>{selectedTask?.title}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Button
            color="error"
            size="small"
            onClick={handleDeleteThisOnly}
            variant="contained"
          >
            This Only
          </Button>
          <Button
            color="error"
            size="small"
            onClick={handleDeleteThisAndFollowing}
            variant="contained"
          >
            This and Following
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RecurringTasks;
