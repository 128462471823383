export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const TOGGLE_BLOCK_USER = "TOGGLE_BLOCK_USER";


export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOGOUT = "LOGOUT";

export const GET_TASKS = "GET_TASKS";
export const GET_TASK = "GET_TASK";
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const DELETE_REPEAT_TASK = "DELETE_REPEAT_TASK";

export const TASK_ERROR = "TASK_ERROR";
export const UPDATE_TASK_STATUS_REQUEST = "UPDATE_TASK_STATUS_REQUEST";
export const UPDATE_TASK_STATUS_SUCCESS = "UPDATE_TASK_STATUS_SUCCESS";
export const UPDATE_TASK_STATUS_FAILURE = "UPDATE_TASK_STATUS_FAILURE";

export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USER";
export const GET_USER = "GET_USER";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const USER_ERROR = "USER_ERROR";
export const USER_LOADED = "USER_LOADED";

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';