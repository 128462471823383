import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  SnackbarContent,
  CircularProgress,
} from "@mui/material";
import DashboardCard from "../../../components/shared/DashboardCard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { makeStyles } from "@mui/styles";
import showLottiePopup from "../../utilities/LottiePopup";
import { createUsers, getUsers } from "../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCategories } from "../../../actions/taskActions";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      marginBottom: theme.spacing(2),
    },
  },
  card: {
    maxWidth: 600,
    margin: "auto",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    margin: "auto",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const NewUser = () => {
  const { users } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);
  const { categories } = useSelector((state) => state.tasks);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  // Form data state
  const [formData, setFormData] = useState({
    userName: "",
    department: "",
    emailID: "",
    phone: "",
    role: "",
    password: "",
    reportingTo: "",
  });

  // Error state for validation
  const [errors, setErrors] = useState({});

  // Fetch all users for the reportingTo field
  useEffect(() => {
    const fetchUsers = async () => {
      dispatch(getUsers());
      dispatch(fetchCategories());
    };
    fetchUsers();
  }, [dispatch]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};

    if (!formData.userName.trim()) {
      newErrors.userName = "User Name is required";
    }
    if (!formData.emailID.trim()) {
      newErrors.emailID = "Email ID is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailID)) {
      newErrors.emailID = "Email ID is invalid";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    if (!formData.department) {
      newErrors.department = "Department is required";
    }
    if (!formData.role) {
      newErrors.role = "Role is required";
    }
    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    if (!formData.reportingTo) {
      newErrors.reportingTo = "Reporting To is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = await dispatch(createUsers(formData));
      if (data) {
        navigate("/all-users");
      }

      setFormData({
        userName: "",
        department: "",
        emailID: "",
        phone: "",
        role: "",
        password: "",
        reportingTo: "",
      });
    }
  };

  return (
    <DashboardCard title="Create User">
      <Card className={classes.card}>
        <CardContent>
          <SnackbarContent
            message={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar className={classes.avatar}>
                  <GroupAddIcon />
                </Avatar>
                <Typography
                  variant="h5"
                  align="center"
                  gutterBottom
                  style={{ marginLeft: "8px" }}
                >
                  Create New User
                </Typography>
              </div>
            }
            style={{ backgroundColor: "black", color: "white" }}
          />

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            className={classes.root}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <TextField
                  required
                  fullWidth
                  name="userName"
                  label="User Name"
                  value={formData.userName}
                  onChange={handleChange}
                  error={!!errors.userName}
                  helperText={errors.userName}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="emailID"
                  label="Email ID"
                  type="email"
                  value={formData.emailID}
                  onChange={handleChange}
                  error={!!errors.emailID}
                  helperText={errors.emailID}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required error={!!errors.department}>
                  <InputLabel>Department</InputLabel>
                  <Select
                    name="department"
                    value={formData.department}
                    onChange={handleChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          width: 300,
                        },
                      },
                    }}
                  >
                    {categories && categories.length > 0 ? (
                      categories.map((cat, index) => {
                        return (
                          <MenuItem key={index} value={cat}>
                            {cat}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="200px"
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Select>
                  {errors.department && (
                    <Typography color="error">{errors.department}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required error={!!errors.role}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Team Leader">Team Leader</MenuItem>
                    <MenuItem value="User">User</MenuItem>
                  </Select>
                  {errors.role && (
                    <Typography color="error">{errors.role}</Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth required error={!!errors.reportingTo}>
                  <InputLabel>Reporting To</InputLabel>
                  <Select
                    name="reportingTo"
                    value={formData.reportingTo}
                    onChange={handleChange}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Set the max height for the dropdown
                          width: 200, // Set the width for the dropdown
                        },
                      },
                    }}
                  >
                    {users && users.length > 0 ? (
                      users
                        .filter(
                          (userData) =>
                            userData.role === "Admin" ||
                            userData.role === "Team Leader"
                        ) // Filter for Admin and Team Leader roles
                        .map((userData) => (
                          <MenuItem
                            key={userData._id}
                            value={userData.userName}
                          >
                            {userData.userName}
                          </MenuItem>
                        ))
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="200px"
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Select>
                  {errors.reportingTo && (
                    <Typography color="error">{errors.reportingTo}</Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Create User
            </Button>
          </Box>
        </CardContent>
      </Card>
    </DashboardCard>
  );
};

export default NewUser;
