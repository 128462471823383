import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
  Stack,
  Box,
  Grid,
  Button,
  Tooltip,
  TablePagination,
  CircularProgress,
  TextField,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import {
  IconCircleCheck,
  IconClock,
  IconCircle,
  IconCircleX,
  IconUserFilled,
  IconClockCheck,
  IconCircleLetterT,
  IconMathGreater,
} from "@tabler/icons-react"; // Example icons, adjust as needed

import DashboardCard from "../../../components/shared/DashboardCard";
import { getAllUsersPerformance } from "../../../actions/dashboardActions";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
const ChartWithText = ({ rate, label, color }) => {
  const DonutChartOptions = {
    chart: {
      type: "donut",
      height: 200,
    },
    labels: [label],
    colors: [color],
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value) => `${value}%`,
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <ReactApexChart
        type="donut"
        options={DonutChartOptions}
        series={[rate, 100 - rate]} // Assuming you want to show the rate and the remainder to 100%
        height={80}
        width={80}
      />
      <span style={{ color }}>{Math.trunc(rate)}%</span>
    </Box>
  );
};

const UsesPerformanceStats = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [usersPerformance, setUsersPerformance] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const fetchUsersPerformance = async () => {
      try {
        setLoading(true); // Start loading
        const response = await getAllUsersPerformance();
        if (currentUser.role === "Team Leader") {
          setUsersPerformance(response.memberStatuses); // Use memberStatuses for Team Leader
        } else {
          setUsersPerformance(response.allUsersPerformance); // Use usersPerformance for other roles
        }
      } catch (error) {
        toast.error("Error fetching users performance");
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchUsersPerformance();
  }, []);

  // Handle pagination
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredUsers = usersPerformance.filter((user) =>
    user.userName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate paginated users
  const paginatedUsers = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <DashboardCard title="All Users Performance">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {" "}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Completion Rate:
          </Typography>
          <Avatar
            sx={{
              bgcolor: "#4CAF50",
              width: 24,
              height: 24,
            }}
          >
            <IconCircle color="#FFFFFF" />
          </Avatar>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          {" "}
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Delayed Rate:
          </Typography>
          <Avatar
            sx={{
              bgcolor: "#F44336",
              width: 24,
              height: 24,
            }}
          >
            <IconCircle color="#FFFFFF" />
          </Avatar>
        </Box>
        <TextField
          size="small"
          id="outlined-basic"
          label="Search by name..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <Box sx={{ overflow: "auto", width: "100%" }}>
        {loading ? (
          <CircularProgress /> // Show loader while fetching data
        ) : (
          paginatedUsers.map((data, index) => (
            <NavLink
              to={`/user-task-report/${data.emailID}`} // Replace with your desired route
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                marginLeft: 16,
              }}
              key={index}
            >
              <TableContainer
                component={Paper}
                sx={{
                  mb: 2,
                  p: 2,
                  backgroundColor: "#F2F6FA",
                  cursor: "pointer",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <Tooltip title="View more" arrow placement="top">
                  <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                bgcolor: "primary.main",
                                width: 35,
                                height: 35,
                              }}
                            >
                              <IconUserFilled color="#FFFFFF" />
                            </Avatar>
                            <Typography variant="h6">
                              ({data.userName})
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <div style={{ display: "flex" }}>
                              <ChartWithText
                                rate={Math.trunc(data.stats?.completionRate)}
                                label="Completion Rate"
                                color="#4CAF50" // Green for completion rate
                              />
                              <ChartWithText
                                rate={Math.trunc(data.stats?.delayedRate)}
                                label="Delayed Rate"
                                color="#F44336" // Red for delayed rate
                              />
                            </div>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 2,
                              alignItems: "center",
                            }}
                          >
                            {/* Other task stats as previously defined */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "primary.main",
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <IconCircleLetterT color="#FFFFFF" />
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                Total Tasks: {data.stats?.totalTasks}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "success.main",
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <IconCircleCheck color="#FFFFFF" />
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                Completed Tasks: {data.stats?.completedTasks}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "warning.main",
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <IconCircle color="#FFFFFF" />
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                In Progress Tasks: {data.stats?.inProgressTasks}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "grey.400",
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <IconClockCheck color="#FFFFFF" />
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                Open Tasks: {data.stats?.openTasks}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "info.main",
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <IconClock color="#FFFFFF" />
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                On Time Tasks: {data.stats?.onTimeTasks}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "error.main",
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <IconCircleX color="#FFFFFF" />
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                Delayed Tasks: {data.stats?.delayedTasks}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "grey.500",
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <IconClock color="#FFFFFF" />
                              </Avatar>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                Overdue Tasks: {data.stats?.overdueTasks}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Tooltip>
              </TableContainer>
            </NavLink>
          ))
        )}
      </Box>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={usersPerformance.length}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </DashboardCard>
  );
};
export default UsesPerformanceStats;
