import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Chip,
  CircularProgress,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardCard from "../../../components/shared/DashboardCard";
import moment from "moment";
import { getSingleUserPerformance } from "../../../actions/dashboardActions";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

// Custom styles for the dashboard
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
    "&.active": {
      backgroundColor: "#5D87FF", // Active card color (green)
      color: "white",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
  },
  tableHeader: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9f9f9",
    },
    "&:hover": {
      backgroundColor: "#e0f7fa",
    },
  },
  cardTitle: {
    fontSize: "1.1rem",
    fontWeight: "500",
    paddingBottom: "10px",
  },
  cardValue: {
    fontSize: "1.3rem",
    fontWeight: "bold",
  },
  container: {
    padding: "30px",
  },
}));

const TaskOverview = ({ taskData, emailID }) => {
  const classes = useStyles();
  const [filteredTasks, setFilteredTasks] = useState([
    ...taskData.tasks.allTasks,
  ]);
  const [activeCard, setActiveCard] = useState("all");

  // Handle card click to filter tasks
  const handleCardClick = (category) => {
    setActiveCard(category);
    switch (category) {
      case "completed":
        setFilteredTasks(taskData.tasks.completed);
        break;
      case "open":
        setFilteredTasks(taskData.tasks.open);
        break;
      case "inProgress":
        setFilteredTasks(taskData.tasks.inProgress);
        break;
      case "overdue":
        setFilteredTasks(taskData.tasks.overdue);
        break;
      case "onTime":
        setFilteredTasks(taskData.tasks.onTime);
        break;
      case "delayed":
        setFilteredTasks(taskData.tasks.delayed);
        break;
      case "all":
        setFilteredTasks(taskData.tasks.allTasks);
        break;
      default:
        setFilteredTasks([...taskData.tasks.allTasks]);
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "primary.main";
      case "Medium":
        return "secondary.main";
      case "Low":
        return "error.main";
      case "Critical":
        return "success.main";
      default:
        return "default";
    }
  };

  return (
    <div className={classes.container}>
      <DashboardCard title={`Task overview of (${emailID})`}>
        {/* Statistics Cards */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={`${classes.card} ${
                activeCard === "all" ? "active" : ""
              }`}
              onClick={() => handleCardClick("all")}
            >
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Total Tasks
                </Typography>
                <Typography className={classes.cardValue}>
                  {taskData.totalTasks}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={`${classes.card} ${
                activeCard === "completed" ? "active" : ""
              }`}
              onClick={() => handleCardClick("completed")}
            >
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Completed Tasks
                </Typography>
                <Typography className={classes.cardValue}>
                  {taskData.completedTasks}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={`${classes.card} ${
                activeCard === "open" ? "active" : ""
              }`}
              onClick={() => handleCardClick("open")}
            >
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Open Tasks
                </Typography>
                <Typography className={classes.cardValue}>
                  {taskData.openTasks}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={`${classes.card} ${
                activeCard === "inProgress" ? "active" : ""
              }`}
              onClick={() => handleCardClick("inProgress")}
            >
              <CardContent>
                <Typography className={classes.cardTitle}>
                  In Progress
                </Typography>
                <Typography className={classes.cardValue}>
                  {taskData.inProgressTasks}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={`${classes.card} ${
                activeCard === "overdue" ? "active" : ""
              }`}
              onClick={() => handleCardClick("overdue")}
            >
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Overdue Tasks
                </Typography>
                <Typography className={classes.cardValue}>
                  {taskData.overdueTasks}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={`${classes.card} ${
                activeCard === "onTime" ? "active" : ""
              }`}
              onClick={() => handleCardClick("onTime")}
            >
              <CardContent>
                <Typography className={classes.cardTitle}>
                  On-Time Tasks
                </Typography>
                <Typography className={classes.cardValue}>
                  {taskData.onTimeTasks}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              className={`${classes.card} ${
                activeCard === "delayed" ? "active" : ""
              }`}
              onClick={() => handleCardClick("delayed")}
            >
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Delayed Tasks
                </Typography>
                <Typography className={classes.cardValue}>
                  {taskData.delayedTasks}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.cardTitle}>
                  Completion Rate
                </Typography>
                <Typography className={classes.cardValue}>
                  {taskData.completionRate}%
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: "20px" }} />

        {/* Task Table */}

        <Box sx={{ width: "100%" }}>
          <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Id
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Category
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Assign To
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Priority
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Due Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>{" "}
            <TableBody>
              {filteredTasks.length > 0 ? (
                filteredTasks.map((task, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle2" fontWeight={600}>
                            {task.title}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{task.category}</TableCell>
                    <TableCell>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                        fontWeight={400}
                      >
                        {task.assignTo}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Chip
                        sx={{
                          px: "4px",
                          backgroundColor: getPriorityColor(task.priority),
                          color: "#fff",
                          height: 25,
                          width: 100,
                        }}
                        style={{ fontWeight: "900" }}
                        size="small"
                        label={task.priority}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" style={{ color: "red" }}>
                        {moment(task.dueDate).format("DD-MMMM-YYYY hh:mm A")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      No tasks available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
    </div>
  );
};

// Example usage of the component
const SingleUserStatsReport = () => {
  const { emailID } = useParams();
  const [taskData, setTaskData] = useState(null); // State for task data
  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        const response = await getSingleUserPerformance(emailID);
        console.log(response);
       

        setTaskData(response.stats);
      } catch (err) {
        setError(err.message); // Handle error
      } finally {
        setLoading(false); // Set loading to false regardless of the outcome
      }
    };

    fetchUserStats();
  }, [emailID]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Typography variant="body1" color="error" align="center">
        {error}
      </Typography>
    );
  }

  // Handle no data state
  if (!taskData || Object.keys(taskData).length === 0) {
    return (
      <Typography variant="body1" align="center">
        No data available for the user.
      </Typography>
    );
  }

  return <TaskOverview taskData={taskData} emailID={emailID} />;
};

export default SingleUserStatsReport;
