import React, { useEffect, useRef, useState } from "react";
import ImagePdf from "../../../assets/images/pdfImage.png";
import DashboardCard from "../../../components/shared/DashboardCard";
import ProfileImg from "../../../assets/images/profile/user-1.jpg";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Card,
  CardContent,
  IconButton,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  CardMedia,
  Popover,
  InputAdornment,
  OutlinedInput,
  Alert,
  styled,
  keyframes,
  Tooltip,
  CircularProgress,
  Pagination,
} from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MicIcon from "@mui/icons-material/Mic";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  deleteTask,
  getTasks,
  updateTask,
  getDelegatedTasks,
  updateTaskStatus,
  updateIsApproved,
  deleteRepeatTask,
} from "../../../actions/taskActions";

import { getUsers } from "../../../actions/userActions";
import { uploadFiles } from "../../../actions/commonFileUpload";
import { useWebSocketService } from "../../../utils/webSocket";
import toast from "react-hot-toast";
const blink = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;
const CustomAlert = styled(Alert)(({ theme }) => ({
  borderColor: "#ffae1f",
  backgroundColor: "#fef5e5",
  "& .MuiAlert-icon": {
    fontSize: "1.3rem",
    animation: `${blink} 3s infinite`,
  },
  "& .MuiAlert-message .blinking-message": {
    fontSize: "1.3rem",
    animation: `${blink} 3s infinite`,
  },
}));
const DelegatedTasks = () => {
  const dispatch = useDispatch();
  const { sendMessage } = useWebSocketService();
  const { tasks, loading: tasksLoading } = useSelector((state) => state.tasks);
  const { categories } = useSelector((state) => state.tasks);
  const { users } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [role, setRole] = useState("User");
  const [allTasks, setAllTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSmallDialog, setOpenSmallDialog] = useState(false);
  const [newAssignTo, setNewAssignTo] = useState("");
  const [newReason, setNewReason] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [showReason, setShowReason] = useState(false);
  const [note, setNote] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [changesAttachments, setChangesAttachments] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedTask, setEditedTask] = useState({
    title: selectedTask?.title,
    description: selectedTask?.description,
    assignTo: selectedTask?.assignTo,
    priority: selectedTask?.priority,
    dueDate: selectedTask?.dueDate,
    category: selectedTask?.category,
    status: selectedTask?.status,
  });
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const [page, setPage] = useState(1);
  const tasksPerPage = 25; // Adjust this number as needed
  const pageCount = Math.ceil(filteredTasks.length / tasksPerPage);

  const validate = () => {
    let tempErrors = {};
    tempErrors.newAssignTo =
      newAssignTo && newAssignTo.emailID ? "" : "Assign To is required";
    tempErrors.newReason = newReason ? "" : "Reason is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleStatusChangeClick = (changedStatus) => {
    setShowReason(true);
    setNewStatus(changedStatus);
  };

  const handleApprovedChangeClick = async (id) => {
    // Ask for confirmation before proceeding with approval
    const isConfirmed = window.confirm(
      "Are you sure you want to approve this task?"
    );

    if (!isConfirmed) {
      return; // If the user cancels, do nothing
    }

    try {
      const response = await updateIsApproved(id);
      console.log("Approval success:", response);
    } catch (error) {
      console.log(error);
      toast.error(
        error.message || "An error occurred while approving the task"
      );
    }
  };

  useEffect(() => {
    dispatch(getDelegatedTasks());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    setAllTasks(tasks);
    setFilteredTasks(tasks);
  }, [tasks]);

  const handleClickCard = (status) => {
    if (status === null) {
      setFilteredTasks(allTasks);
    } else {
      setFilteredTasks(allTasks.filter((task) => task.status === status));
    }
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSmallOpen = (taskDetail) => {
    setSelectedTaskId(taskDetail._id);
    const assignedUser =
      taskDetail.assignTo && taskDetail.assignTo.length > 0
        ? taskDetail.assignTo[0]
        : "";
    setNewAssignTo(assignedUser); // Set to the current assigned user
    setNewReason(""); // Reset the reason field
    setOpenSmallDialog(true);
  };

  const handleSmallClose = () => {
    setNewAssignTo("");
    setNewReason("");
    setOpenSmallDialog(false);
  };

  const handleSmallSubmit = (e) => {
    e.preventDefault();
    if (!selectedTaskId) return;
    if (validate()) {
      const formData = {
        transfer: {
          reasonToTransfer: newReason,
        },
        assignTo: newAssignTo,
      };
      dispatch(updateTask(selectedTaskId, formData, sendMessage));
      handleSmallClose();
    }
  };

  const handleSave = (e, taskId) => {
    e.preventDefault();

    dispatch(
      updateTaskStatus(
        taskId,
        newStatus,
        note,
        attachments,
        user?.emailID,
        sendMessage
      )
    );

    setShowReason(false);
    setNote("");
    setNewStatus("");
    setAttachments([]);
    handleDialogClose();
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "primary.main";
      case "Medium":
        return "secondary.main";
      case "Low":
        return "error.main";
      case "Critical":
        return "success.main";
      default:
        return "default";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Open":
        return "success";
      case "In Progress":
        return "warning";
      case "Completed":
        return "error";
      default:
        return "default";
    }
  };
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const paginatedTasks = filteredTasks.slice(
    (page - 1) * tasksPerPage,
    page * tasksPerPage
  );
  const calculateTicketStats = () => {
    const total = allTasks?.length || 0;
    const pending =
      allTasks?.filter((task) => task.status === "In Progress").length || 0;
    const open = allTasks?.filter((task) => task.status === "Open").length || 0;
    const closed =
      allTasks?.filter((task) => task.status === "Completed").length || 0;

    return { total, pending, open, closed };
  };

  const { total, pending, open, closed } = calculateTicketStats();

  const handleViewClick = (task) => {
    setSelectedTask(task);
    setEditedTask(task);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setShowReason(false);
    setEditMode(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleEditSave = () => {
    dispatch(updateTask(editedTask._id, editedTask, sendMessage));
    setEditMode(false);
  };

  const handleDeleteClick = (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this task?"
    );
    if (confirmed) {
      dispatch(deleteTask(id, sendMessage));
    }
  };

  const handleRepeatDeleteClick = (groupId) => {
    if (!groupId) {
      alert("No scheduled task found.");
      return;
    }

    // If groupId is valid, proceed with the deletion
    const confirmed = window.confirm(
      "Are you sure you want to delete this and following tasks?"
    );

    if (confirmed) {
      dispatch(deleteRepeatTask(groupId, sendMessage));
    }
  };


  const handleIconClick = (type) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = type;
    input.multiple = true;
    input.onchange = handleStatusAttachmentChange;
    input.click();
  };
  const renderAttachmentPreview = (attachment, index) => {
    switch (attachment.type) {
      case "image":
        return <ImageIcon />;
      case "application":
        if (attachment.name.endsWith(".pdf")) {
          return <PictureAsPdfIcon />;
        }
        return <InsertDriveFileIcon />;
      default:
        return null;
    }
  };

  const handleStatusAttachmentChange = async (e) => {
    const files = Array.from(e.target.files);
    setLoading(true);

    try {
      const fileUrls = await uploadFiles(files);

      setAttachments((prevAttachments) => [
        ...prevAttachments,
        ...fileUrls.map((url, index) => ({
          type: files[index].type.split("/")[0],
          path: url,
          name: files[index].name,
        })),
      ]);
    } catch (error) {
      // Handle the error if necessary
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };
  if (tasksLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div style={{ width: "fit-content" }}>
      <DashboardCard title="All Delegated Tasks">
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Card
                onClick={() => handleClickCard(null)}
                sx={{
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  "&:hover": { transform: "scale(1.05)" },
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                style={{ backgroundColor: "rgb(236, 242, 255)" }}
              >
                <CardContent style={{ color: "rgb(93, 135, 255)" }}>
                  <Typography variant="h4" align="center">
                    {total}
                  </Typography>
                  <Typography variant="h6" align="center" gutterBottom>
                    Total Tasks
                  </Typography>
                </CardContent>
              </Card>
            </Grid>{" "}
            <Grid item xs={3}>
              <Card
                onClick={() => handleClickCard("Open")}
                sx={{
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  "&:hover": { transform: "scale(1.05)" },
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                style={{ backgroundColor: "rgb(230, 255, 250)" }}
              >
                <CardContent style={{ color: "rgb(19, 222, 185)" }}>
                  <Typography variant="h4" align="center">
                    {open}
                  </Typography>
                  <Typography variant="h6" align="center" gutterBottom>
                    Open Tasks
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                onClick={() => handleClickCard("In Progress")}
                sx={{
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  "&:hover": { transform: "scale(1.05)" },
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                style={{ backgroundColor: "rgb(254, 245, 229)" }}
              >
                <CardContent style={{ color: "rgb(255, 174, 31)" }}>
                  <Typography variant="h4" align="center">
                    {pending}
                  </Typography>
                  <Typography variant="h6" align="center" gutterBottom>
                    In-Progress Tasks
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card
                onClick={() => handleClickCard("Completed")}
                sx={{
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  "&:hover": { transform: "scale(1.05)" },
                  height: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                style={{ backgroundColor: "rgb(253, 237, 232)" }}
              >
                <CardContent style={{ color: "rgb(250, 137, 107)" }}>
                  <Typography variant="h4" align="center">
                    {closed}
                  </Typography>
                  <Typography variant="h6" align="center" gutterBottom>
                    Completed Tasks
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: 2,
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 250 }}>
            <TextField
              label="Search..."
              name="search"
              value={searchTerm}
              onChange={handleSearchTermChange}
              size="small"
            />
          </FormControl>
        </Box>
        <Box sx={{ overflow: "auto", width: "100%" }}>
          <Table
            aria-label="simple table"
            sx={{
              whiteSpace: "nowrap",
              mt: 2,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Id
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Task Title
                  </Typography>
                </TableCell>
                {role === "Admin" ? null : (
                  <>
                    <TableCell>
                      <Typography variant="h6" fontWeight={600}>
                        Assigned To
                      </Typography>
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Priority
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Due On
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Updated Time
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedTasks
                .filter((task) => {
                  // Ensure task.assignTo is an array before calling .some()
                  if (Array.isArray(task.assignTo)) {
                    return task.assignTo.some(
                      (user) =>
                        user.emailID
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                        user.userName
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                    );
                  }
                  return false; // Exclude the task if assignTo is not an array
                })
                .map((taskDetail, index) => (
                  <TableRow key={taskDetail.id}>
                    <TableCell>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "200px", // Set a specific width for the Box
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          fontWeight={600}
                          noWrap // This adds white-space: nowrap, overflow: hidden, and text-overflow: ellipsis automatically
                        >
                          {taskDetail.title}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Typography
                        color="textSecondary"
                        variant="subtitle2"
                        fontWeight={400}
                      >
                        {taskDetail.assignTo[0].userName}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Chip
                        sx={{
                          px: "4px",
                          backgroundColor: getPriorityColor(
                            taskDetail.priority
                          ),
                          color: "#fff",
                          height: 25,
                          width: 100,
                        }}
                        style={{ fontWeight: "900" }}
                        size="small"
                        label={taskDetail.priority}
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={taskDetail.status}
                        color={getStatusColor(taskDetail.status)}
                        size="small"
                        sx={{
                          height: 25,
                          width: 100,
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        style={{ color: "green" }}
                      >
                        {moment(taskDetail.dueDate).format(
                          "DD-MMMM-YYYY hh:mm A"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#3f51b5" }}
                      >
                        {moment(taskDetail.updatedAt).fromNow()}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        aria-label="view"
                        color="primary"
                        onClick={() => handleViewClick(taskDetail)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton onClick={() => handleSmallOpen(taskDetail)}>
                        <SyncAltIcon />
                      </IconButton>
                        {user.role !== "User" &&
                      user.emailID === taskDetail.createdBy.emailID ? (
                     
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={() => handleDeleteClick(taskDetail._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        
                      ) : (
                        <IconButton aria-label="delete" color="error" disabled>
                          <DeleteIcon />
                        </IconButton>
                      )}
                      <Dialog open={openSmallDialog} onClose={handleSmallClose}>
                        <DialogTitle>Want To Transfer Task ?</DialogTitle>
                        <DialogContent>
                          <FormControl fullWidth margin="normal">
                            <TextField
                              labelId="select-label"
                              value={newAssignTo ? newAssignTo.emailID : ""}
                              label="Assign To"
                              onChange={(e) => {
                                const selectedUser = users.find(
                                  (user) => user.emailID === e.target.value
                                );
                                setNewAssignTo(selectedUser);
                              }}
                              fullWidth
                              required
                              select
                              error={!!errors.newAssignTo}
                              helperText={errors.newAssignTo}
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200,
                                    },
                                  },
                                },
                              }}
                            >
                              {users.map((option) => (
                                <MenuItem
                                  key={option._id}
                                  value={option.emailID}
                                >
                                  <div>
                                    <ListItemText
                                      primary={option.userName}
                                      style={{ color: "blue" }}
                                    />
                                    <ListItemText primary={option.emailID} />
                                  </div>
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>

                          <TextField
                            label="Enter Reason to change..."
                            multiline
                            required
                            rows={4}
                            value={newReason}
                            onChange={(e) => setNewReason(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!errors.newReason}
                            helperText={errors.newReason}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleSmallClose} color="error">
                            Cancel
                          </Button>
                          <Button
                            onClick={(e) => handleSmallSubmit(e)}
                            color="primary"
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Box>
        </Box>
      </DashboardCard>
      {selectedTask && (
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>About Task</DialogTitle>
          <DialogContent>
            <Card sx={{ mt: 2 }} variant="outlined">
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <u>
                    <Typography variant="h6">Task Details :</Typography>
                  </u>
                  {editMode ? (
                    <Box>
                      <Button
                        onClick={() => setEditMode(false)}
                        variant="contained"
                        color="error"
                        sx={{ mr: 1 }}
                        endIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleEditSave}
                        variant="contained"
                        color="success"
                        endIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                    </Box>
                  ) : (
                    user.role !== "User" && (
                      <IconButton
                        onClick={() => setEditMode(true)}
                        color="primary"
                        style={{ border: "1px solid #5D87FF" }}
                      >
                        <EditIcon />
                      </IconButton>
                    )
                  )}
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Title:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {editMode ? (
                      <TextField
                        fullWidth
                        name="title"
                        value={editedTask.title}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <Typography variant="body1" gutterBottom>
                        {selectedTask.title}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Description:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {editMode ? (
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        name="description"
                        value={editedTask.description}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <Typography variant="body1" gutterBottom>
                        {selectedTask.description}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Category:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {editMode ? (
                      <TextField
                        select
                        fullWidth
                        name="category"
                        value={editedTask.category}
                        onChange={handleInputChange}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          },
                        }}
                      >
                        {categories.map((cat, index) => (
                          <MenuItem key={index} value={cat}>
                            {cat}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <Typography variant="body1" gutterBottom>
                        {selectedTask.category}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Assigned By:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      <b>({selectedTask.createdBy.userName})</b>{" "}
                      {selectedTask.createdBy.emailID}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Assigned To:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {editMode ? (
                      <TextField
                        select
                        fullWidth
                        name="assignTo"
                        value={
                          editedTask.assignTo.length > 0
                            ? editedTask.assignTo[0].emailID
                            : ""
                        }
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          },
                        }}
                        onChange={(e) => {
                          const selectedEmail = e.target.value;
                          const selectedUser = users.find(
                            (user) => user.emailID === selectedEmail
                          );
                          handleInputChange({
                            target: {
                              name: "assignTo",
                              value: selectedUser ? [selectedUser] : [],
                            },
                          });
                        }}
                      >
                        {users.map((option) => (
                          <MenuItem key={option._id} value={option.emailID}>
                            <div>
                              <ListItemText
                                primary={option.userName}
                                style={{ color: "blue" }}
                              />
                              <ListItemText primary={option.emailID} />
                            </div>
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <Typography variant="body1" gutterBottom>
                        {selectedTask.assignTo.length > 0 ? (
                          <>
                            <b>({selectedTask.assignTo[0].userName})</b>{" "}
                            {selectedTask.assignTo[0].emailID}
                          </>
                        ) : (
                          "No assignee"
                        )}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Priority:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {editMode ? (
                      <TextField
                        select
                        fullWidth
                        name="priority"
                        value={editedTask.priority}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="Low">Low</MenuItem>
                      </TextField>
                    ) : (
                      <Chip
                        sx={{
                          px: "4px",
                          backgroundColor: getPriorityColor(
                            selectedTask.priority
                          ),
                          color: "#fff",
                        }}
                        style={{ fontWeight: "900" }}
                        size="small"
                        label={selectedTask.priority}
                      />
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Status:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {editMode ? (
                      <TextField
                        select
                        fullWidth
                        name="status"
                        value={editedTask.status}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="Open">Open</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                      </TextField>
                    ) : (
                      <Chip
                        label={selectedTask.status}
                        color={getStatusColor(selectedTask.status)}
                        size="small"
                      />
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Due Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {editMode ? (
                      <TextField
                        type="datetime-local"
                        fullWidth
                        name="dueDate"
                        value={moment(editedTask.dueDate).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <Typography variant="body1" gutterBottom color={"green"}>
                        {moment(selectedTask.dueDate).format(
                          "DD-MMMM-YYYY hh:mm A"
                        )}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Created At:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      {moment(selectedTask.createdAt).format(
                        "DD-MMMM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Updated At:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1" gutterBottom>
                      {moment(selectedTask.updatedAt).format(
                        "DD-MMMM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </Grid>

                  {selectedTask.closedAt && (
                    <>
                      {" "}
                      <Grid item xs={4}>
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          gutterBottom
                        >
                          Completed At:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          color={"error"}
                        >
                          {moment(selectedTask.closedAt).format(
                            "DD-MMMM-YYYY hh:mm A"
                          )}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      gutterBottom
                    >
                      Attachments:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {selectedTask.attachments &&
                      selectedTask.attachments.map((attachment, index) => (
                        <Box key={index} sx={{ mb: 1 }}>
                          <Typography variant="body1" gutterBottom>
                            {attachment.type === "image" && (
                              <a
                                href={attachment?.path}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                                <img
                                  src={attachment.path}
                                  alt={`Attachment ${index}`}
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                  }}
                                />
                              </a>
                            )}
                            {attachment.type === "application" && (
                              <a
                                href={attachment?.path}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={ImagePdf}
                                  alt={`Attachment ${index}`}
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                  }}
                                />
                              </a>
                            )}
                            {attachment.type === "audio" && (
                              <audio controls>
                                <source
                                  src={attachment.path}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            )}
                            {attachment.type === "video" && (
                              <video
                                width="320"
                                height="240"
                                controls
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              >
                                <source
                                  src={attachment.path}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </Typography>
                        </Box>
                      ))}
                  </Grid>
                  {selectedTask.transfer?.reasonToTransfer && (
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={600}
                        gutterBottom
                      >
                        <CustomAlert variant="outlined" severity="warning">
                          <span className="blinking-message">
                            Transferred Task!
                          </span>
                          <Grid container style={{ marginTop: "10px" }}>
                            <Grid item xs={12}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <span>(Whom Transferred) : </span>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      backgroundColor: (theme) =>
                                        theme.palette.primary.main,
                                      marginRight: "8px",
                                      marginLeft: "5px",
                                      width: 35,
                                      height: 35,
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "8px",
                                    }}
                                  >
                                    <b>
                                      {selectedTask.transfer?.fromWhom.userName}
                                    </b>
                                    <Chip
                                      label={
                                        selectedTask.transfer?.fromWhom.emailID
                                      }
                                      color="primary"
                                      size="small"
                                      style={{ marginTop: "4px" }} // Adds spacing between username and chip
                                    />
                                  </div>
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: "5px" }}>
                              <Typography variant="body1" gutterBottom>
                                <span>(Reason For Transfer) : </span>{" "}
                                {selectedTask.transfer?.reasonToTransfer}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CustomAlert>
                      </Typography>
                    </Grid>
                  )}

                  {selectedTask.statusChanges &&
                    selectedTask.statusChanges.length > 0 && (
                      <Grid item xs={12}>
                        {selectedTask.statusChanges.map((change, index) => (
                          <Card
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#f2f6fa",
                            }}
                          >
                            <CardContent>
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={4}>
                                  <Chip
                                    label={change.status}
                                    color={getStatusColor(change.status)}
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      src={ProfileImg}
                                      alt={ProfileImg}
                                      sx={{
                                        width: 35,
                                        height: 35,
                                      }}
                                    />{" "}
                                    <b style={{ marginLeft: "10px" }}>
                                      ({change.taskUpdatedBy})
                                    </b>
                                  </Typography>
                                  <Typography variant="body1" gutterBottom>
                                    {change.note}
                                  </Typography>{" "}
                                  <span
                                    style={{
                                      color: "#539bff",
                                    }}
                                  >
                                    {moment(change.changedAt).fromNow()}{" "}
                                  </span>
                                </Grid>
                                {change.changesAttachments &&
                                  change.changesAttachments.length > 0 && (
                                    <Grid item xs={12}>
                                      <Grid container spacing={2}>
                                        {change.changesAttachments.map(
                                          (attachment, index) => {
                                            return (
                                              <Grid item key={index}>
                                                {attachment.type ===
                                                  "image" && (
                                                  <a
                                                    href={attachment?.path}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {" "}
                                                    <img
                                                      src={attachment.path}
                                                      alt={`Attachment ${index}`}
                                                      style={{
                                                        maxWidth: "100px",
                                                        maxHeight: "100px",
                                                      }}
                                                    />
                                                  </a>
                                                )}

                                                {attachment.type ===
                                                  "application" && (
                                                  <a
                                                    href={attachment?.path}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <img
                                                      src={ImagePdf}
                                                      alt={`Attachment ${index}`}
                                                      style={{
                                                        maxWidth: "100px",
                                                        maxHeight: "100px",
                                                      }}
                                                    />
                                                  </a>
                                                )}
                                                {attachment.type ===
                                                  "audio" && (
                                                  <audio controls>
                                                    <source
                                                      src={attachment.path}
                                                      type="audio/mpeg"
                                                    />
                                                    Your browser does not
                                                    support the audio element.
                                                  </audio>
                                                )}

                                                {attachment.type ===
                                                  "video" && (
                                                  <video
                                                    width="320"
                                                    height="240"
                                                    controls
                                                    style={{
                                                      maxWidth: "200px",
                                                      maxHeight: "120px",
                                                    }}
                                                  >
                                                    <source
                                                      src={attachment.path}
                                                      type="video/mp4"
                                                    />
                                                    Your browser does not
                                                    support the video tag.
                                                  </video>
                                                )}
                                              </Grid>
                                            );
                                          }
                                        )}
                                      </Grid>
                                    </Grid>
                                  )}
                              </Grid>
                            </CardContent>
                          </Card>
                        ))}
                      </Grid>
                    )}
                </Grid>
              </CardContent>{" "}
            </Card>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              gutterBottom
              sx={{ mr: 2, mt: 2 }}
            >
              Change Status:
            </Typography>
            <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
              {selectedTask.isApproved ? (
                <>
                  <VerifiedIcon
                    style={{ color: "#41967b" }}
                    fontSize="large"
                    sx={{ ml: 2 }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ ml: 1, color: "#41967b", fontWeight: "bold" }}
                  >
                    (Task Approved)
                  </Typography>{" "}
                </>
              ) : selectedTask.status === "Completed" ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mr: 2 }}
                    onClick={() => handleStatusChangeClick("Open")}
                  >
                    ReOpen
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    style={{ background: "#41967b" }}
                    endIcon={<CheckCircleOutlineIcon />}
                    onClick={() => handleApprovedChangeClick(selectedTask._id)}
                  >
                    Approve
                  </Button>
                </>
              ) : (
                <>
                  {selectedTask.status !== "In Progress" && (
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{ mr: 2 }}
                      onClick={() => handleStatusChangeClick("In Progress")}
                    >
                      In-Progress
                    </Button>
                  )}
                  {selectedTask.status !== "Completed" && (
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ mr: 2 }}
                      onClick={() => handleStatusChangeClick("Completed")}
                    >
                      Completed
                    </Button>
                  )}
                </>
              )}
            </Box>
            {showReason && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                  Reason for Change:
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Enter note for status change"
                />
                <Box display="flex" alignItems="center" sx={{ mt: 2 }} gap={2}>
                  <ImageIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleIconClick("image/*")}
                  />
                  <PictureAsPdfIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleIconClick(".pdf")}
                  />
                  <InsertDriveFileIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleIconClick("application")}
                  />
                </Box>

                {loading && <CircularProgress sx={{ mt: 2 }} />}
                {!loading &&
                  attachments.map((attachment, index) => (
                    <Box key={index} display="flex" alignItems="center" mt={2}>
                      {renderAttachmentPreview(attachment, index)}
                      <IconButton onClick={() => handleDeleteAttachment(index)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Box>
                  ))}

                <Button
                  onClick={(e) => handleSave(e, selectedTask._id)}
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Update Task
                </Button>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default DelegatedTasks;
