import React, { useEffect, useState } from "react";
import DashboardCard from "../../../components/shared/DashboardCard";
import {
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ip } from "../../../utils/ipconfig";
import toast from "react-hot-toast";
import axios from "axios";
import {
  fetchTickets,
  updateTicketStatus,
} from "../../../actions/ticketActions";

const Tickets = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { tickets, loading, error } = useSelector((state) => state.tickets);
  const [selectedStatus, setSelectedStatus] = useState("Select");
  console.log(tickets);

  useEffect(() => {
    dispatch(fetchTickets()); // Fetch tickets when component loads
  }, []);

  const handleStatusChange = (_id) => (event) => {
    const newStatus = event.target.value;
    const confirmed = window.confirm(
      `Are you sure you want to change the status to "${newStatus}"?`
    );
    if (!confirmed) return;

    dispatch(updateTicketStatus(_id, newStatus)); // Dispatch Redux action
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Open":
        return "success";
      case "In Progress":
        return "warning";
      case "Closed":
        return "error";
      case "Resolved":
        return "primary";
      default:
        return "default";
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div style={{ width: "fit-content" }}>
      <DashboardCard title="All Tickets">
        <Box sx={{ width: "100%" }}>
          <Table aria-label="simple table" sx={{ whiteSpace: "nowrap", mt: 2 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Id
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Title
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Description
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Name
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Create Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" fontWeight={600}>
                    Update Date
                  </Typography>
                </TableCell>
                {user.role === "Admin" && (
                  <TableCell>
                    <Typography variant="h6" fontWeight={600}>
                      Action
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.map((taskDetail, index) => (
                <TableRow key={taskDetail.id}>
                  <TableCell>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle2" fontWeight={600}>
                          {taskDetail.title}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      fontWeight={400}
                    >
                      {taskDetail.description}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={taskDetail.status}
                      color={getStatusColor(taskDetail.status)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      fontWeight={400}
                    >
                      {taskDetail.createdBy?.userName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {moment(taskDetail.createdAt).format(
                        "DD-MMMM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {moment(taskDetail.updateAt).format(
                        "DD-MMMM-YYYY hh:mm A"
                      )}
                    </Typography>
                  </TableCell>
                  {user.role === "Admin" && (
                    <TableCell>
                      <Select
                        value={selectedStatus}
                        onChange={handleStatusChange(taskDetail._id)}
                        size="small"
                        displayEmpty
                        renderValue={(selected) => (
                          <Chip
                            label={selected}
                            color={getStatusColor(selected)}
                            sx={{ fontWeight: 500 }}
                          />
                        )}
                      >
                        {["Open", "In Progress", "Resolved", "Closed"].map(
                          (status) => (
                            <MenuItem key={status} value={status}>
                              {status}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DashboardCard>
    </div>
  );
};

export default Tickets;
