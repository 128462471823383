import { combineReducers } from 'redux';
import authReducer from './authReducer';
import taskReducer from './taskReducer';
import userReducer from './userReducer';
import { ticketReducer } from './ticketReducer';

export default combineReducers({
  auth: authReducer,
  tasks: taskReducer,
  users: userReducer,
  tickets: ticketReducer,
});
